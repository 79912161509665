import React, { useEffect, useState } from "react";
import { PageProps, graphql } from "gatsby";
import { SEO } from "../components/seo";
import DetailPageConferenceAd from "../components/detail-page-conference-ad";
import { getAuthorNames } from "../components/talk-tile";

type DataType = {
  markdownRemark: any;
  allSponsorsJson: {
    nodes: SponsorType[];
  };
};

type SponsorType = {
  name: string;
  logo: string;
  weight: string;
  link: string;
  pitch: string;
};

const ENABLE_SPONSORSHIP = false;

export function Head({
  data: { markdownRemark }, // this prop will be injected by the GraphQL query below.
}: PageProps<DataType>) {
  const { frontmatter } = markdownRemark;
  return (
    <SEO
      title={`${frontmatter.title} - ${frontmatter.author}`}
      pathname={frontmatter.slug || undefined}
      image={frontmatter.thumbnail || undefined}
      description={`${frontmatter.title}: a talk by ${getAuthorNames(
        frontmatter.author
      )} presented at ${frontmatter.conference} ${
        frontmatter.edition
      } about ${frontmatter.tags.join(", ")}`}
      keywords={frontmatter.tags.join(", ")}
    />
  );
}

export default function Template({
  data: { markdownRemark, allSponsorsJson }, // this prop will be injected by the GraphQL query below.
}: PageProps<DataType>) {
  function extractRelativePath(absolutePath?: string): string | null {
    if (absolutePath == null) {
      return null;
    }
    const pathRegex = /data\/(talks\/\S*$)/i;
    const relativePathMatches = absolutePath.match(pathRegex);
    if (relativePathMatches == null) {
      return null;
    }
    return relativePathMatches.length > 1 ? relativePathMatches[1] : null;
  }

  function weightedRandom(allSponsors) {
    const weightSum = allSponsors
      .map((a) => a.weight)
      .reduce((a, b) => a + b, 0);
    let i,
      sum = 0;
    const r = Math.random();
    for (i in allSponsors) {
      sum += allSponsors[i].weight / weightSum;
      if (r <= sum) return allSponsors[i];
    }
  }

  const { frontmatter, html, fileAbsolutePath } = markdownRemark;
  const [sponsor, setSponsor] = useState<SponsorType | undefined>();
  useEffect(() => {
    if (frontmatter.allow_ads == true && !sponsor) {
      setSponsor(weightedRandom(allSponsorsJson.nodes));
    }
  });
  const documentRelativePath = extractRelativePath(fileAbsolutePath);
  return (
    <>
      <div className="fixed h-full w-full gradient bg-gradient-to-tr from-slate-900 from-70% to-slate-800 -z-10">
        &nbsp;
      </div>
      <div className="leading-normal tracking-normal text-white gradient bg-transparent">
        <nav
          id="header"
          className="fixed w-full z-30 top-0 text-white bg-black/30"
        >
          <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-4">
            <div className="pl-4 flex items-center">
              <a
                className="toggleColour text-white no-underline hover:no-underline font-bold text-2xl lg:text-4xl"
                href="/"
              >
                <code>async</code>
              </a>
            </div>
          </div>
        </nav>
        <div className="pt-24">
          <div className="container px-3 mx-auto">
            <header className="mb-4 lg:mb-8">
              <div>
                <h2 className="text-2xl xl:text-4xl font-regular">
                  {getAuthorNames(frontmatter.author)}
                </h2>
                <h1 className="text-4xl xl:text-6xl font-bold">
                  {frontmatter.title}
                </h1>
              </div>

              <div className="text-xl flex gap-1 my-2 font-normal">
                {frontmatter.tags &&
                  frontmatter.tags.map((tag) => (
                    <span
                      key={tag}
                      className="px-1 text-xs rounded bg-slate-500"
                    >
                      {tag}
                    </span>
                  ))}
              </div>
            </header>

            {/* Video Container */}
            <iframe
              className="w-full aspect-video"
              src={`https://www.youtube.com/embed/${frontmatter.video}`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>

            {/* Download */}
            {frontmatter.slides && (
              <div className="mt-2 lg:mt-4">
                <a href={frontmatter.slides}>
                  <div className="p-4 text-lg font-bold rounded-lg hover:underline transition ease-in-out delay-50 hover:bg-slate-700 duration-500">
                    Download the Presentation
                  </div>
                </a>
              </div>
            )}

            {/* Sponsor */}
            {ENABLE_SPONSORSHIP && sponsor && (
              <div className="sm:max-w-prose mx-auto mt-4 lg:mt-8">
                <a href={sponsor.link}>
                  <div className="border-blue-900 px-4 py-2 rounded-md border-2 relative hover:bg-blue-900 duration-500 w-full">
                    <span className="absolute top-2 right-2 text-sm font-light bg-blue-900 rounded-md px-1 py-0 text-white">
                      SPONSOR
                    </span>
                    <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
                      <div className="basis-1/4 sm:self-center grow">
                        <img
                          className="w-full max-w-md sm:max-w-full mx-auto"
                          src={`/sponsors/${sponsor.logo}`}
                        />
                      </div>
                      <div className="basis-3/4 grow">
                        <div className="text-xl font-bold">{sponsor.name}</div>
                        <div>{sponsor.pitch}</div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            )}

            {/* Conference ad */}
            {frontmatter.conference && (
              <div className="mt-4 lg:mt-8">
                <DetailPageConferenceAd
                  conferenceSlug={frontmatter.conference}
                />
              </div>
            )}

            {/* Transcript */}
            <div className="mt-4 lg:mt-8">
              <div className="p-4 text-lg font-bold">Transcript</div>
              <div
                className="blog-post-content text-xl mb-12 max-w-prose mx-auto px-4"
                dangerouslySetInnerHTML={{ __html: html || "N/A" }}
              />
            </div>

            {/* Link to GitHub */}
            {documentRelativePath && (
              <a
                className="hover:underline"
                href={`https://github.com/techconnection-io/async-techconnection-data/blob/main/${documentRelativePath}`}
              >
                <div className="mt-4 lg:mt-8 text-xl mb-12 max-w-prose mx-auto bg-slate-600 px-3 py-2 rounded-md hover:bg-slate-500">
                  Edit on GitHub
                </div>
              </a>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      fileAbsolutePath
      html
      thumbnailImage {
        publicURL
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        video
        slides
        thumbnail
        author
        tags
        conference
        edition
        allow_ads
      }
    }
    allSponsorsJson {
      nodes {
        name
        logo
        weight
        link
        pitch
      }
    }
  }
`;
